import {FC, useState} from 'react';
import classnames from 'classnames';
import {HTMLInputAttributes} from '@/modules/core/reactDivAPI/types/HTMLInputAttributes';
// @ts-ignore
import {MegaphoneIcon, XMarkIcon} from '@heroicons/react/24/outline'
import SVGIcon from "@/components/SVG";


type Announcement = {
  mobilePrimaryTextValue: string;
  desktopPrimaryTextValue: string;
  tailwindWrapperClassNames: any;
  tailwindContainerClassNames: any;
  destination?: string;
  destinationLabel?: string;
}

const Annoucement: FC<Announcement> = ({
                                         mobilePrimaryTextValue,
                                         desktopPrimaryTextValue,
                                         tailwindWrapperClassNames,
                                         tailwindContainerClassNames,
                                         destination,
                                         destinationLabel = 'Learn more'
                                       }) => {

  const [hideAnnouncement, setHideAnnouncement] = useState(false);

  const handleHideAnnouncement = () => {
    // console.log('clicked me')
    setHideAnnouncement(!hideAnnouncement)
  }

  return (
    <div
      className={classnames(
        tailwindWrapperClassNames && Object.values(tailwindWrapperClassNames),
        hideAnnouncement && 'hidden'
      )}
    >
      <div
        className={classnames(
          tailwindContainerClassNames && Object.values(tailwindContainerClassNames)
        )}
      >

        {/* Megaphone Icon & Primary Text Value */}
        <div className="flex w-0 flex-1 items-center">
          <span className="flex rounded-lg p-2">
            <SVGIcon
              name={"heroiconsMegaphone"}
              stroke="#ff6600"
              viewBox="0 0 24 24"
              className={"h-8 w-8 text-[#ff6600]"}
            />
          </span>
          <p className="ml-3 mr-2 font-medium text-white text-[0.97rem]">
            <span className="md:hidden">{mobilePrimaryTextValue && mobilePrimaryTextValue}</span>
            <span className="hidden md:inline">{desktopPrimaryTextValue && desktopPrimaryTextValue}</span>
          </p>
        </div>
        {destination && (
          <div
            className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
            <a
              href={destination}
              className="flex items-center justify-center rounded-md border border-transparent bg-[#ff6600] px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-indigo-50 no-underline"
            >
              {destinationLabel}
            </a>
          </div>
        ) || (
          <div
            className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
            <a
              href="/our-blog/what-coronavirus-means-for-your-local-brisbane-dentist"
              className="flex items-center justify-center rounded-md border border-transparent bg-[#ff6600] px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-indigo-50 no-underline"
            >
              {destinationLabel}
            </a>
          </div>
        )}

        {/* Dismiss Button */}
        <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
          <button
            type="button"
            className="-mr-1 flex rounded-md p-2 hover:bg-[#dc262633] focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
            onClick={handleHideAnnouncement}
          >
            <span className="sr-only">Dismiss</span>
            <SVGIcon
              name={"heroiconsXMarker"}
              stroke="#dc2626"
              viewBox="0 0 24 24"
              className={"h-6 w-6 text-[#ff6600]"}
            />
          </button>
        </div>

      </div>
    </div>
  );
};

export default Annoucement;
